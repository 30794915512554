import { Carousel } from "flowbite-react";
import { useContext, useEffect, useState } from "react";
import { isMobile, scrollToTop } from "../shared/utils/javascript";
import { Slider } from "../shared/types";
import { FiltroEstoque } from "../components/filtroEstoque";
import { CardVeiculo } from "../components/cardVeiculo";
import { useNavigate } from "react-router-dom";
import logo from '../assets/logo.webp'
import { buscarSlide } from "../service/api";
import { AuthContext } from "../shared/context/auth";
import { Endereco } from "../shared/utils/constants";
const Home: React.FC = () => {
    const navigate = useNavigate()
    const { estoque, infosLoja: loja } = useContext(AuthContext)
    const [slide, setSlide] = useState<Slider[]>([]);
    const [load, setLoad] = useState<boolean>(false);
    const estilos = {
        root: {
          base: "relative h-full w-full",
          leftControl: "absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none",
          rightControl: "absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none"
        },
        indicators: {
          active: {
            off: "bg-accent hover:bg-white dark:bg-gray-800/50 dark:hover:bg-gray-800 hidden",
            on: "bg-primary dark:bg-primary hidden"
          },
          base: "h-2 w-2 rounded-full",
          wrapper: "absolute bottom-5 left-1/2 flex -translate-x-1/2 space-x-3"
        },
        item: {
          base: "absolute top-1/2 left-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
          wrapper: {
            off: "w-full flex-shrink-0 transform cursor-default snap-center",
            on: "w-full flex-shrink-0 transform cursor-grab snap-center"
          }
        },
        control: {
          base: "inline-flex h-8 w-8 items-center justify-center rounded-full bg-white group-hover:bg-white/80 group-focus:outline-none group-focus:ring-4 group-focus:ring-white dark:bg-accent/30 dark:group-hover:bg-accent/60 dark:group-focus:ring-accent/70 sm:h-10 sm:w-10",
          icon: "h-5 w-5 text-primary dark:text-primary sm:h-6 sm:w-6"
        },
        scrollContainer: {
          base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-none",
          snap: "snap-x"
        }
    };
    const estilosHiddenControls = {
        root: {
          base: "relative h-full w-full",
          leftControl: "absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none",
          rightControl: "absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none"
        },
        indicators: {
          active: {
            off: "hidden",
            on: "hidden"
          },
          base: "hidden",
          wrapper: "hidden"
        },
        item: {
          base: "absolute top-1/2 left-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
          wrapper: {
            off: "w-full flex-shrink-0 transform cursor-default snap-center",
            on: "w-full flex-shrink-0 transform cursor-grab snap-center"
          }
        },
        control: {
          base: "hidden",
          icon: "hidden"
        },
        scrollContainer: {
          base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-none",
          snap: "snap-x"
        }
    };
    const handleGetSlide = async () => {
      const result = await buscarSlide();
      setSlide(result?.data)
  }
  useEffect(() => {
      handleGetSlide();
  },[])
    useEffect(() => {
        scrollToTop()
    },[window.location.pathname])
    const findBanner = () => {
      const mobile = slide?.filter((x: { isMobile: boolean; }) => x.isMobile === true) 
      const desktop = slide?.filter((x: { isMobile: boolean; }) => x.isMobile === false) 
      return isMobile() && mobile?.length > 0 ? mobile : desktop
    }
    useEffect(() => {
      const handleScroll = () => {
        if (!load) {
          setLoad(true);
        }
      };
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [load]);
    const extrairNomeDeUsuario = (url: string) => {
      // Expressão regular para extrair o que está entre ".com/" e a próxima barra "/"
      const regex = /\.com\/([^/]+)/;
      const match = url.match(regex);
      
      if (match && match[1]) {
        return match[1];
      } else {
        return null; // Retorna null se não houver correspondência
      }
    }
    return (
        <div className="bg-secondary">
            <div className="h-[65vh] mobile:h-[75vh] mobile:relative">
                <Carousel slideInterval={3000} theme={findBanner().length <= 1 ? estilosHiddenControls : estilos}>
                {findBanner().length === 0 ? (
                  <div className="w-full flex justify-center">
                    <span className="loading loading-dots loading-lg"></span>
                  </div>
                ) : findBanner()?.map((x: Slider) => (
                  <img key={x.id} className='object-cover h-full w-full' src={`${process.env.REACT_APP_FOTO}${x.arquivo}`} alt="..." />
              ))}
                </Carousel>
            </div>
            <div className="flex justify-center mt-9 items-center w-full">
                <FiltroEstoque />
            </div>
            <div className="py-8 flex flex-col justify-center items-center text-accent bg-black mt-16">
                <h1 className="font-bold text-3xl text-white mobile:text-xl">Veículos em destaque</h1>
            </div>
                {load && (
                  <>
            <div className="flex flex-col gap-10 justify-center items-center pb-6 mobile:pb-2 mobile:gap-8 bg-black">
                <div className="grid grid-cols-3 max-w-[95rem] gap-11 mobile:grid-cols-1 px-3 mobile:gap-5 mobile:hidden">
                    {estoque?.slice(0,6).map?.((x) => (
                        <CardVeiculo key={x.cod_veiculo} veiculo={x} />
                    ))}
                </div>
                <div className="grid grid-cols-4 px-8 gap-11 mobile:grid-cols-1 mobile:px-3 mobile:gap-5 desktop:hidden">
                    {estoque?.slice(0,3).map?.((x) => (
                        <CardVeiculo key={x.cod_veiculo} veiculo={x} />
                    ))}
                </div>
                <button aria-label="Ver todos os veículos" onClick={() => navigate('/estoque')} className="btn btn-primary w-full rounded-none text-white text-xl font-bold px-8">Acesso todo o estoque</button>
            </div>
            <div className="text-accent flex items-center justify-center gap-72 py-10 mobile:gap-8 mobile:flex-col-reverse">
                <div className="flex flex-col gap-8 justify-center items-center">
                    <div className="flex gap-24 items-center mobile:flex-col mobile:gap-14">
                        <img loading="lazy" src={logo} alt="Logo" className="object-cover max-w-56" />
                        <div className="flex flex-col items-center gap-8 mobile:gap-3">
                            <h1 className="font-bold text-white mobile:text-sm">Siga a GR Select no instagram</h1>
                            <h1 onClick={() => window.open(loja.loja.tbl_infosLoja[0]?.urlInstagram, "_blank")} className="text-white cursor-pointer">@{extrairNomeDeUsuario(loja.loja.tbl_infosLoja[0]?.urlInstagram)}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-black w-full flex flex-col justify-center">
              <h1 className="text-white font-bold my-5 w-full text-center">Nossa localização</h1>
              <div className="w-full">
                  <iframe title='Mapa' src={Endereco.link} width="100%" height="100%" style={{border:"10px", height: "300px"}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
                  </>
                )}
        </div>
    )
}
export default Home;