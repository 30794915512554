import { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom';
import { getInfosLoja, putInfosLoja } from '../../service/api';
import Cookies from 'js-cookie';
import { HeaderAdmin } from './headerAdmin';
import { AsideAdmin } from './asideAdmin';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';

export const Configuracoes = () => {
    const navigate = useNavigate()
    const [result, setResult] = useState<any>()
    const [formData, setFormData] = useState({
        descricao: "",
        urlInstagram: "",
        urlFacebook: "",
        horarioSegSex: "",
        horarioSab: "",
        horarioDomingo: ""
    });

    const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

      const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        putInfosLoja(formData);
        toast.success('Informações alteradas com sucesso!');
      };

    useEffect(()=> {
        const loginCookies = Cookies.get('token');
        if(loginCookies === undefined) {
            navigate('/admin')
        }
        return
    },[navigate])

    const buscaAcessos = async () => {
        if(formData.descricao !== "") return
        try {
            const infos = await getInfosLoja()
            setResult(infos);
            setFormData({
                descricao: infos?.data?.loja.tbl_infosLoja[0].descricao ?? "",
                urlFacebook: infos?.data?.loja.tbl_infosLoja[0].urlFacebook ?? "",
                urlInstagram: infos?.data?.loja.tbl_infosLoja[0].urlInstagram ?? "",
                horarioSegSex: infos?.data?.loja.tbl_infosLoja[0].horarioSegSex ?? "",
                horarioSab: infos?.data?.loja.tbl_infosLoja[0].horarioSab ?? "",
                horarioDomingo: infos?.data?.loja.tbl_infosLoja[0].horarioDomingo ?? "",
            })
        } catch (error) {       
            //
        }
    }

    useEffect(() => {
        buscaAcessos();
    },[])


    return (
        <div>
            <div className='flex flex-col absolute w-full z-0'>
                <HeaderAdmin />
                <AsideAdmin />
            </div>
            <div className='p-28'>
                <div className='flex items-center gap-3'>
                    <i className="ri-dashboard-3-line ri-3x"></i>
                    <h1 className='font-semibold text-2xl'>Painel</h1>
                </div>
                <hr className='w-full h-px' />
                <div className='flex mt-5 items-center gap-2 ml-5 mb-2'>
                    <i className="ri-tools-line ri-2x"></i>
                    <h3 className='text-lg font-semibold'>Configurações</h3>
                </div>
                <div className='grid grid-cols-2 gap-14 p-5 z-10 relative'>
                    <div>
                        <h3>Sobre nós</h3>
                        <ReactQuill onChange={(value: string) => {
                            setFormData(prevState => ({
                                ...prevState,
                                descricao: value ?? ""
                            }))
                        }}
                        defaultValue={formData.descricao} value={formData.descricao} placeholder="Sobre nós" />
                    </div>
                    <div className='flex flex-col gap-5'>
                        <div>
                            <h3>Url Instagram:</h3>
                            <input defaultValue={formData.urlInstagram} onChange={handleInputChange} id='urlInstagram' name='urlInstagram' type="text" placeholder="Instagram" className="input input-bordered w-full bg-white" />
                        </div>
                        <div>
                            <h3>Url Facebook</h3>
                            <input defaultValue={formData.urlFacebook} onChange={handleInputChange} id='urlFacebook' name='urlFacebook' type="text" placeholder="Facebook" className="input input-bordered w-full bg-white" />
                        </div>
                        <div>
                            <h3>Horario Seg. a Sex.</h3>
                            <input defaultValue={formData.horarioSegSex} onChange={handleInputChange} id='horarioSegSex' name='horarioSegSex' type="text" placeholder="Horario de Seg. à Sex." className="input input-bordered w-full bg-white" />
                        </div>
                        <div>
                            <h3>Horario Sab.</h3>
                            <input defaultValue={formData.horarioSab} onChange={handleInputChange} id='horarioSab' name='horarioSab' type="text" placeholder="Horario Sab." className="input input-bordered w-full bg-white" />
                        </div>
                        <div>
                            <h3>Horario Dom.</h3>
                            <input defaultValue={formData.horarioDomingo} onChange={handleInputChange} id='horarioDomingo' name='horarioDomingo' type="text" placeholder="Horario Dom." className="input input-bordered w-full bg-white" />
                        </div>
                        <button onClick={handleSubmit} className='btn btn-primary'>Salvar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}