//================================================
// Envios para o back end

//================================================
// Retornos do back end
export enum Banner {
  BANNER = "banner",
  ID = "id",
  TITULO = "titulo",
  URL = "url",
  SITUACAO = "situacao",
  POSICAO = "posicao",
  DATA_INICIO = "data_inicio",
  DATA_FIM = "data_fim",
  ARQUIVO = "arquivo",
  IS_MOBILE = "isMobile",

  SLIDES = "slides",
}

export enum Veiculo {
  ANO = "ano",
  CATEGORIA = "categoria",
  MARCA = "marca",
  MODELO = "modelo",
  COMBUSTIVEL = "combustivel",
  FOTOS = "fotos",
  VALOR = "valor",
  VEICULO = "veiculo",
  COD_VEICULO = "cod_veiculo",
  VERSAO = "versao",
  QUILOMETRAGEM = "km",
  OPCIONAIS = "opcionais",
  COR = "cor",
  MOTOR = "motor",
  FINAL_PLACA = "finalPlaca",
  OBS_SITE = "obs_site",

  CODIGO_OPCIONAIS = "codigo",
  DESCRICAO_OPCIONAIS = "descricao",
}

export enum InfosLoja {
  NOME = "nome",
  LOJA = "loja",
}

export enum DescLoja {
  DESCRICAO = "descricao",
  INSTAGRAM = "urlInstagram",
  HORARIO_DOM = "horarioDomingo",
  HORARIO_SAB = "horarioSab",
  HORARIO_SEG = "horarioSegSex",
  FACEBOOK = "urlFacebook",
  INFOS_LOJA = "infosLoja",
  TBL_INFOS_LOJA = "tbl_infosLoja"
}

export enum Endereco {
  BAIRRO = 'bairro',
  CEP = 'cep',
  CIDADE = 'cidade',
  LOGRADOURO = 'logradouro',
  UF = 'uf',
}

export enum Telefone {
  TELEFONE = 'telefone',
  WHATSAPP = 'whatsapp'
}

export enum Email {
  EMAIL = 'email'
}

export enum NomeLoja {
  NOME_LOJA = 'nome_empresa'
}

export enum Acessos {
  DIA = 'dia',
  MES = 'mes',
  ANO = 'ano',
  ACESSOS = 'acessos'
}

export enum Whatsapp {
  DATA_HORA = "data_hora",
  DISPOSITIVO = "dispositivo",
  CLIQUES = "whatsappCliques",
}

//================================================
// Helpers
export enum Contador {
  CONTADOR = "contador",
}
