import { useContext, useEffect, useState } from "react";
import { Email, Endereco, NomeLoja } from "../shared/types";
import { Endereco as EnderecoDictionary, InfosLoja as InfosLojaDictionary, Telefone as TelefoneDictionary, Email as EmailDictionary, NomeLoja as NomeLojaDictionary, DescLoja as DescLojaDictionary } from "../shared/utils/dictionary";
import { useNavigate } from "react-router-dom";

import logoLitoral from '../assets/logoLitoral.webp'
import { getEmails, getEnderecos, getLoja, postWhats } from "../service/api";
import { AuthContext } from "../shared/context/auth";
import { isMobile } from "../shared/utils/javascript";

export const Footer = () => {

    const { telefone, ip, infosLoja: loja } = useContext(AuthContext);

    const navigate = useNavigate()
    const [endereco, setEndereco] = useState<Endereco[]>([])
    const [email, setEmail] = useState<Email[]>([])
    const [nomeLoja, setNomeLoja] = useState<NomeLoja>()
    const [load, setLoad] = useState<boolean>(false);

    const segundoPontoIndex = loja?.[InfosLojaDictionary.LOJA]?.[DescLojaDictionary.TBL_INFOS_LOJA]?.[0]?.[DescLojaDictionary.DESCRICAO].indexOf('.', loja?.[InfosLojaDictionary.LOJA][DescLojaDictionary.TBL_INFOS_LOJA][0][DescLojaDictionary.DESCRICAO].indexOf('.'));
    const textoAteSegundoPonto = loja?.[InfosLojaDictionary.LOJA]?.[DescLojaDictionary.TBL_INFOS_LOJA]?.[0]?.[DescLojaDictionary.DESCRICAO].substring(0, segundoPontoIndex ?? 1 + 1);

    const telefoneWhats = telefone.filter((x) => x.whatsapp === "1")
    const telefoneRamal = telefone.filter((x) => x.whatsapp === "0")

    function removeHTMLTags(input: string) {
        const doc = new DOMParser().parseFromString(input, 'text/html');
        return doc.body.textContent || "";
    }

    const textoSemHtml = removeHTMLTags(textoAteSegundoPonto ?? "")

    const handleGetNomeLoja = async () => {
        try {
            const result = await getLoja()
            setNomeLoja(result?.data.retorno ?? {
                nome_empresa: ''
            }) 
        } catch {
            //
        }
    }
    const handleGetEndereco = async () => {
        try {
            const result = await getEnderecos()
            setEndereco(result?.data.retorno ?? []) 
        } catch {
            //
        }
    }
    const handleGetEmail = async () => {
        try {
            const result = await getEmails()
            setEmail(result?.data.retorno ?? []) 
        } catch {
            //
        }
    }

    useEffect(() => {
        const handleScroll = () => {
          if (!load) {
            setLoad(true);
            handleGetEmail();
            handleGetEndereco();
            handleGetNomeLoja();
          }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, [load]);

    return (
        <div className="bg-black text-white mobile:mb-12">
            {isMobile() ? (
                <div className="flex flex-col items-center px-3 w-full text-center">
                    <p className="flex items-center gap-2 leading-5 text-xs">Copyright © 2023 Todos os direitos reservados para {nomeLoja?.[NomeLojaDictionary.NOME_LOJA]} - Desenvolvido por</p>
                    <img onClick={() => window.open("https://www.litoralcar.com.br/", "_blank")} className="max-h-5 cursor-pointer object-cover mt-[-22px] ml-8" src={logoLitoral} alt="Litoral Car" />
                </div>
            ) : (
                <div className="py-3 flex justify-between px-28">
                    <div className="flex flex-row gap-2">
                        <i className="ri-instagram-line ri-lg cursor-pointer" onClick={() => window.open(loja?.[InfosLojaDictionary.LOJA][DescLojaDictionary.TBL_INFOS_LOJA][0][DescLojaDictionary.INSTAGRAM], "_blank")}></i>
                        <i className="ri-facebook-circle-line ri-lg cursor-pointer" onClick={() => window.open(loja?.[InfosLojaDictionary.LOJA][DescLojaDictionary.TBL_INFOS_LOJA][0][DescLojaDictionary.FACEBOOK], "_blank")}></i>
                    </div>
                    <p className="flex items-center gap-2 text-xs">Copyright © 2023 Todos os direitos reservados para {nomeLoja?.[NomeLojaDictionary.NOME_LOJA]} - Desenvolvido por <img onClick={() => window.open("https://www.litoralcar.com.br/", "_blank")} className="max-h-5 cursor-pointer object-cover" src={logoLitoral} alt="Litoral Car" /> <span onClick={() => navigate('/termos')} className="underline cursor-pointer">Politica de privacidade e termos de uso</span></p>
                </div>
            )}
        </div>
    )
}