export const Termos = () => {

    return (
        <div className="w-fit items-center flex justify-center mobile:px-3 py-16 mobile:py-4">
            <h2 className="w-1/2 text-justify leading-8 p-3 shadow mobile:w-full" style={{background: "linear-gradient(rgb(241, 241, 241) 0%, rgb(255, 255, 255) 99.1%)"}}>Termo de Consentimento para Tratamento de Dados Pessoais e Politicas Gerais de Utilização
                Este documento visa registrar a manifestação livre, informada e inequívoca pela qual o Titular concorda com o tratamento de seus dados pessoais para finalidade específica, em conformidade com a Lei nº 13.709 – Lei Geral de Proteção de Dados Pessoais (LGPD).
                Ao acessar o site, o Titular consente com o presente termo e concorda que a Controladora, tome decisões referentes a manipulação de seus dados pessoais, bem como realize o tratamento de seus dados pessoais, envolvendo operações como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.
                Dados Pessoais
                O Controlador fica autorizado a tomar decisões referentes ao tratamento de todos os dados pessoais do Titular solicitados nos formulários contidos nesse local, além de informações contidas nos cookies, que têm como objetivo rastrear ações, comportamentos e características diversas de navegação para melhor experiência de uso.
                Finalidades do Tratamento dos Dados
                O tratamento dos dados pessoais listados neste termo tem as seguintes finalidades:
                Possibilitar que o Controlador identifique e entre em contato com o Titular para fins de relacionamento comercial. Possibilitar que o Controlador envie ou forneça ao Titular seus produtos e serviços condizentes com o formulário preenchido. Compartilhamento de Dados
                O Controlador fica autorizado a compartilhar os dados pessoais do Titular com outros agentes de tratamento de dados, caso seja necessário para as finalidades listadas neste termo, observados os princípios e as garantias estabelecidas pela Lei nº 13.709. Segurança dos Dados
                O Controlador fará os melhores esforços para a manutenção de medidas de segurança, técnicas e administrativas aptas a proteger os dados pessoais de acessos e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito.
                Em conformidade ao art. 48 da Lei nº 13.709, o Controlador comunicará ao Titular e à Autoridade Nacional de Proteção de Dados (ANPD) a ocorrência de incidente de segurança que possa acarretar risco ou dano relevante ao Titular.
                Término do Tratamento dos Dados
                O Controlador poderá manter e tratar os dados pessoais do Titular durante todo o período em que os mesmos forem pertinentes ao alcance das finalidades listadas neste termo. Dados pessoais anonimizados, sem possibilidade de associação ao indivíduo, poderão ser mantidos por período indefinido.
                O Titular poderá solicitar via e-mail ou correspondência ao Controlador, a qualquer momento, que sejam eliminados os dados pessoais não anonimizados do Titular. O Titular fica ciente de que poderá ser inviável ao Controlador continuar o fornecimento de produtos ou serviços ao Titular a partir da eliminação dos dados pessoais.
                Direitos do Titular
                O Titular tem direito a obter do Controlador, em relação aos dados por ele tratados, a qualquer momento e mediante requisição: I - confirmação da existência de tratamento; II - acesso aos dados; III - correção de dados incompletos, inexatos ou desatualizados; IV - anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na Lei nº 13.709; V - portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial; VI - eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas hipóteses previstas no art. 16 da Lei nº 13.709; VII - informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados; VIII - informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa; IX - revogação do consentimento, nos termos do § 5º do art. 8º da Lei nº 13.709.
                Direito de Revogação do Consentimento Este consentimento poderá ser revogado pelo Titular, a qualquer momento, mediante solicitação via e-mail ou correspondência ao Controlador.
            </h2>
        </div>
    )
}