import axios from "axios";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { Acessos, Banner, Email, Endereco, InfosLoja, NomeLoja, Retorno, Slider, Slides, Telefone, Veiculo, Whatsapp } from "../shared/types";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API}/`,
});
const apiEstoque = axios.create({
  baseURL: process.env.REACT_APP_API_ESTOQUE,
});

const data = {
  "username": process.env.REACT_APP_USER,
  "password": process.env.REACT_APP_PASSWORD,
};

const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), `${process.env.REACT_APP_CHAVE_API}`).toString();

const acesso = "kYCPkJXlsMoQOzuHNJFezOzaHSsgIHtC3WKmOg67Af5OZpQ7MVY7G"
const tokenCookies = Cookies.get('token');
const cod_loja = Number(process.env.REACT_APP_COD_LOJA)

let emailRequestMade = false;
let estoqueRequestMade = false;
let lojaRequestMade = false;
let telefoneRequestMade = false;

// API Estoque ----------------------------------------------------------------------------------------------------------------------------------------------------
export const getEstoque = () => {
  if (!estoqueRequestMade) {
    estoqueRequestMade = true;
  try {
    return apiEstoque.post<Retorno<Veiculo[]>>(`/buscar/estoque/${process.env.REACT_APP_COD_LOJA}`, {data: encryptedData});
  } catch (error) {
    //
  }
}
};

export const getVeiculo = (codVeiculo : string) => {
  try {
    return apiEstoque.post<Retorno<Veiculo>>(`/buscar/veiculo/${codVeiculo}/${process.env.REACT_APP_COD_LOJA}`, {data: encryptedData});
  } catch (error) {
    //
  }
};

export const getLoja = () => {
  try {
    return apiEstoque.post<Retorno<NomeLoja>>(`/buscar/loja/${process.env.REACT_APP_COD_LOJA}`, {data: encryptedData});
  } catch (error) {
    //
  }
};

export const getTelefones = () => {
  if (!telefoneRequestMade) {
    telefoneRequestMade = true;
  try {
    return apiEstoque.post<Retorno<Telefone[]>>(`/buscar/telefones/${process.env.REACT_APP_COD_LOJA}`, {data: encryptedData});
  } catch (error) {
    //
  }
}
};

export const getEnderecos = () => {
  try {
    return apiEstoque.post<Retorno<Endereco[]>>(`/buscar/enderecos/${process.env.REACT_APP_COD_LOJA}`, {data: encryptedData});
  } catch (error) {
    //
  }
};

export const getEmails = () => {
  if (!emailRequestMade) {
    emailRequestMade = true;
    try {
      return apiEstoque.post<Retorno<Email[]>>(`/buscar/emails/${process.env.REACT_APP_COD_LOJA}`, {data: encryptedData});
    } catch (error) {
      //
    }
  }
};

// API Admin ----------------------------------------------------------------------------------------------------------------------------------------------------
export const postLogin = (data: { login: string; senha: string; }) => {
  try {
    return api.post<any>(`/auth/login` , {
      email: data.login,
      senha: data.senha,
      lojaId: Number(cod_loja)
    }, {headers: {
      'access': acesso
  }});
  } catch (error) {
    //  
  }
};
export const getInfosLoja = async () => {
  if (!lojaRequestMade) {
    lojaRequestMade = true;
  try {
    return api.get<InfosLoja>(`/loja/buscar?id=${process.env.REACT_APP_COD_LOJA}`, {
      headers: {
        'access': acesso
      }
    });
  } catch (error) {
    //
  }
}
};
export const putInfosLoja = (data: any) => {
  try {
    return api.put<any>(`/infos-loja/alterar`, data, {
      headers: {
        'token': tokenCookies
      }
    });
  } catch (error) {
    //
  }
};

export const editBanner = (data: any) => {
  try {
    return api.put<any>(`/banner/alterar`, data, {headers: {
      'token': `${tokenCookies}`,
    }});
  } catch (error) {
    //
  }
};
export const postBanner = (formData: any) => {
  try {
    return api.post<any>(`/banner/salvar`, formData, {headers: {
      'token': `${tokenCookies}`,
      'Content-Type': 'multipart/form-data'
    }});
  } catch (error) {
    //
  }
};
export const deleteBanner = (id: number) => {
  try {
    return api.delete<any>(`/banner/deletar?id=${id}`, {headers: {
      'token': `${tokenCookies}`,
    }});
  } catch (error) {
    //
  }
};
export const getBanner = () => {
  try {
    return api.get<Banner>(`/banner/buscar?lojaId=${process.env.REACT_APP_COD_LOJA}` , {headers: {
        'access': acesso
    }});
  } catch (error) {
    //
  }
};
export const buscarSlide = () => {
  try {
    return api.get<any>(`/slide/ativo?lojaId=${process.env.REACT_APP_COD_LOJA}` , {headers: {
      'access': acesso,
  }});
  } catch (error) {
    //
  }
};

export const postVisitas = (data: any) => {
  try {
    return api.post<any>(`/acesso/salvar`, data, {headers: {
        'access': `${acesso}`,
    }});
  } catch (error) {
    //
  }
};
export const getVisitasDias = () => {
  try {
    return api.get<Acessos[]>(`/acesso/buscar?periodo=dias`, {headers: {
      'token': `${tokenCookies}`,
    }});
  } catch (error) {
    throw error
  }
};
export const getVisitasMeses = () => {
  try {
    return api.get<Acessos[]>(`/acesso/buscar?periodo=meses`, {headers: {
      'token': `${tokenCookies}`,
    }});
  } catch (error) {
    throw error
  }
};
export const getVisitasAnos = () => {
  try {
    return api.get<Acessos[]>(`/acesso/buscar?periodo=anos`, {headers: {
      'token': `${tokenCookies}`,
    }});
  } catch (error) {
    throw error
  }
};

export const postWhats = (data: any) => {
  try {
    return api.post<any>(`/whatsapp-cliques/salvar`, data, {headers: {
        'access': `${acesso}`,
    }});
  } catch (error) {
    //
  }
};
export const getWhatsApp = () => {
  try {
    return api.get<Whatsapp>(`/whatsapp-cliques/buscar`, {headers: {
      'token': `${tokenCookies}`,
    }});
  } catch (error) {
    //
  }
};

export const postEmail = (data: any) => {
  try {
    return api.post<any>(`/email/enviar`, {
      to: data.to,
      replyTo: data.replyTo,
      subject: data.subject,
      html: data.html,
      lojaId: cod_loja,
    }, {headers: {
      'access': acesso,
  }});
  } catch (error) {
    //
  }
};