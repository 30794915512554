import React, { createContext, useState } from "react";
import { AuthState, InfosLoja, Telefone, Veiculo } from "../types";
import { InfosLoja as InfosLojaDictionary } from "../utils/dictionary";

interface AuthProviderProps {
  children: React.ReactNode;
}

const defaultState: AuthState = {
  setTelefone: (telefones: Telefone[]) => {},
  telefone: [],
  setIp: (ip: string) => {},
  ip: "",
  setEmail: (email: string) => {},
  email: "",
  setEstoque: (estoque: Veiculo[]) => {},
  estoque: [],
  setInfosLoja: (infoLoja: InfosLoja) => {},
  infosLoja: {
    [InfosLojaDictionary.LOJA]: {
      nome: "",
      tbl_infosLoja: []
    }
  },
};

export const AuthContext = createContext(defaultState);

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [telefone, setTelefoneState] = useState<Telefone[]>(defaultState.telefone);
  const [ip, setIpState] = useState<string>(defaultState.ip);
  const [email, setEmailState] = useState<string>(defaultState.email);
  const [estoque, setEstoqueState] = useState<Veiculo[]>(defaultState.estoque);
  const [infosLoja, setInfosLojaState] = useState<InfosLoja>(defaultState.infosLoja);

  function setTelefone(telefones: Telefone[]) {
    setTelefoneState(telefones);
  }
  function setIp(ip: string) {
    setIpState(ip);
  }
  function setEmail(email: string) {
    setEmailState(email);
  }
  function setEstoque(estoque: Veiculo[]) {
    setEstoqueState(Array.isArray(estoque) ? estoque : []);
  }
  function setInfosLoja(infoLoja: InfosLoja) {
    setInfosLojaState(infoLoja);
  }

  return (
    <AuthContext.Provider value={{ setTelefone, telefone, setIp, ip, setEmail, email, setEstoque, estoque, infosLoja, setInfosLoja }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;