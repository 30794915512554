import { useContext, useState } from "react"
import { Veiculo } from "../shared/types"
import { Veiculo as VeiculoDictionary } from "../shared/utils/dictionary"
import { postEmail } from "../service/api"
import { AuthContext } from "../shared/context/auth"

type ModalPropostaType = {
    veiculo: Veiculo
}

export const ModalProposta = ({veiculo}: ModalPropostaType) => {

    const { email } = useContext(AuthContext);
    const [formEnvio, setFormEnvio] = useState({
        nome: "",
        email: "",
        telefone: "",
        mensagem: `Olá, tenho interesse nesse: ${veiculo[VeiculoDictionary.MARCA]} ${veiculo[VeiculoDictionary.MODELO]} - ${veiculo[VeiculoDictionary.ANO]} - no valor de ${veiculo[VeiculoDictionary.VALOR].toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        })}`
    })

    const handleSubmit = () => {
        postEmail({
            to: email,
            replyTo: formEnvio.email,
            subject: "Proposta",
            html: {
                nome: formEnvio.nome,
                telefone: formEnvio.telefone,
                email: formEnvio.email,
                mensagem: formEnvio.mensagem,
            },
            lojaId: Number(process.env.REACT_APP_COD_LOJA),
          })
          setFormEnvio({
            nome: "",
            email: "",
            telefone: "",
            mensagem: `Olá, tenho interesse nesse: ${veiculo[VeiculoDictionary.MARCA]} ${veiculo[VeiculoDictionary.MODELO]} - ${veiculo[VeiculoDictionary.ANO]} - no valor de ${veiculo[VeiculoDictionary.VALOR].toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL'
            })}`
        })
        const modal = document.getElementById('modal_proposta') as HTMLDialogElement;;
                
            if (modal) {
                modal.close();
            } else {
                console.error("Element with ID 'modal_proposta' not found.");
            }
    }

    return (
        <dialog id="modal_proposta" className="modal">
            <div className="modal-box p-10 flex flex-col gap-5">
                <h2 className="font-semibold">Deixe sua mensagem caso tenha dúvidas</h2>
                <input onChange={(event) => {
                    setFormEnvio(prevState => ({
                        ...prevState,
                        nome: event.target.value
                        }));
                }} type="text" value={formEnvio.nome} placeholder="Nome" className="input input-bordered w-full" />
                <input onChange={(event) => {
                    setFormEnvio(prevState => ({
                        ...prevState,
                        email: event.target.value
                        }));
                }} type="text" value={formEnvio.email} placeholder="E-mail" className="input input-bordered w-full" />
                <input onChange={(event) => {
                    setFormEnvio(prevState => ({
                        ...prevState,
                        telefone: event.target.value
                        }));
                }} type="text" value={formEnvio.telefone} placeholder="Telefone" className="input input-bordered w-full" />
                <textarea onChange={(event) => {
                    setFormEnvio(prevState => ({
                        ...prevState,
                        mensagem: event.target.value
                        }));
                }} value={formEnvio.mensagem} placeholder="Mensagem" className="textarea textarea-bordered w-full" />
                <button className="btn btn-primary font-bold" onClick={handleSubmit}>Enviar mensagem</button>
            </div>
            <form method="dialog" className="modal-backdrop">
                <button>close</button>
            </form>
        </dialog>
    )
}