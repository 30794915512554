import { useNavigate } from "react-router-dom"
import { Veiculo } from "../shared/types"
import { Veiculo as VeiculoDictionary } from "../shared/utils/dictionary"
import { ModalProposta } from "./modalProposta"
import semImagem from '../assets/semImagem.webp'

type CardVeiculoType = {
    veiculo: Veiculo
}

export const CardVeiculo = ({ veiculo }: CardVeiculoType) => {

    const navigate = useNavigate();

    const img = `https://litoralcar.com.br/foto-resize-site/X/${veiculo?.[VeiculoDictionary.COD_VEICULO]}/${process.env.REACT_APP_COD_LOJA}/${veiculo?.[VeiculoDictionary.FOTOS][0]}`

    const cambio = veiculo[VeiculoDictionary.OPCIONAIS].find(x => x.codigo === 21)

    const handleNavigate = () => {
        const paramsObj = { 
            cod_veiculo: veiculo[VeiculoDictionary.COD_VEICULO],
            marca: veiculo[VeiculoDictionary.MARCA],
            modelo: veiculo[VeiculoDictionary.MODELO],
            versao: veiculo[VeiculoDictionary.VERSAO]
        };

        const paramsString = encodeURIComponent(JSON.stringify(paramsObj));
      
        const urlComObjeto = `/veiculo?params=${paramsString}`;
        navigate(urlComObjeto)
    }

    return (
        <div className="flex flex-col items-end bg-[#333333] text-white mobile:min-w-[95vw] justify-between" style={{boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)"}}>
            <ModalProposta veiculo={veiculo} />
            {veiculo[VeiculoDictionary.FOTOS].length === 0 ? (
                <div className="relative pt-[75%] w-full">
                    <img src={semImagem} alt={veiculo[VeiculoDictionary.VEICULO]} className="cursor-pointer object-cover absolute w-full h-full top-0" onClick={handleNavigate} />
                </div>
            ) : (
                <div className="relative pt-[75%] w-full">
                    <img src={img} alt={veiculo[VeiculoDictionary.VEICULO]} className="cursor-pointer object-cover absolute w-full h-full top-0" onClick={handleNavigate} />
                </div>
            )}
            <div className="py-6 px-8 flex flex-col gap-3 w-full">
                <h1 className="font-bold">{veiculo[VeiculoDictionary.MARCA]} {veiculo[VeiculoDictionary.MODELO]} {veiculo[VeiculoDictionary.VERSAO]}</h1>
                <div className="flex flex-col gap-3">
                <h1 className="text-primary font-bold">
                    {veiculo[VeiculoDictionary.VALOR].toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                    })}
                </h1>
                <hr className="h-0.5 bg-white w-full" />
                </div>
                <div className="grid grid-cols-2 gap-5 mt-3">
                    <div className="flex gap-5">
                        <i className="ri-dashboard-3-line ri-lg"></i>
                        <h2>{Number(veiculo[VeiculoDictionary.QUILOMETRAGEM]).toLocaleString()}Km</h2>
                    </div>
                    <div className="flex gap-5">
                        <i className="ri-git-merge-line ri-lg"></i>
                        <h2>{cambio?.[VeiculoDictionary.DESCRICAO_OPCIONAIS] ?? "-"}</h2>
                    </div>
                    <div className="flex gap-5">
                        <i className="ri-calendar-2-line ri-lg"></i>
                        <h2>{veiculo[VeiculoDictionary.ANO] ?? "-"}</h2>
                    </div>
                    <div className="flex gap-5">
                        <i className="ri-gas-station-fill ri-lg"></i>
                        <h2>{veiculo?.[VeiculoDictionary.COMBUSTIVEL] ? veiculo?.[VeiculoDictionary.COMBUSTIVEL] : "-"}</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}