import { useNavigate } from 'react-router-dom'
import Logo from '../assets/logo.webp'
import { isMobile } from '../shared/utils/javascript';

export const Header = () => {

    const navigate = useNavigate();

    const verifyRoute = (path: string) => {
        if(path === window.location.pathname) return true
    }

    return (
        <div className="sticky top-0 bg-black px-3 py-5 flex items-center gap-12 z-50 mobile:px-3 mobile:py-3 justify-around">
            <img className='cursor-pointer object-cover w-44 h-full' onClick={() => navigate('/')} src={Logo} alt="Logo" />
            {isMobile() ? (
                <div className="dropdown dropdown-end">
                    <div tabIndex={0} role="button" aria-label="Hamburguer" className="btn text-white outline-none border-none"><i className="ri-menu-line ri-3x text-primary font-normal"></i></div>
                    <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-max">
                    <li aria-label="Início"><h2 onClick={() => navigate('/')} className={`cursor-pointer ${verifyRoute('/') ? "font-semibold" : ""}`}>Início</h2></li>
                    <li aria-label="Sobre nós"><h2 onClick={() => navigate('/sobre-nos')} className={`cursor-pointer ${verifyRoute('/sobre-nos') ? "font-semibold" : ""}`}>Sobre nós</h2></li>
                    <li aria-label="Estoque"><h2 onClick={() => navigate('/estoque')} className={`cursor-pointer ${verifyRoute('/estoque') ? "font-semibold" : ""}`}>Estoque</h2></li>
                    <li aria-label="Financiamento"><h2 onClick={() => navigate('/financiamento')} className={`cursor-pointer ${verifyRoute('/financiamento') ? "font-semibold" : ""}`}>Financiamento</h2></li>
                    <li aria-label="Contato"><h2 onClick={() => navigate('/contato')} className={`cursor-pointer ${verifyRoute('/contato') ? "font-semibold" : ""}`}>Contato</h2></li>
                    <li aria-label="Avaliação"><h2 onClick={() => navigate('/avaliacao')} className={`cursor-pointer ${verifyRoute('/avaliacao') ? "font-semibold" : ""}`}>VENDA SEU VEÍCULO</h2></li>
                    </ul>
                </div>
            ) : (
            <div className='text-primary flex items-center gap-12'>
                <h2 onClick={() => navigate('/')} className={`cursor-pointer ${verifyRoute('/') ? "font-semibold" : ""}`}>INÍCIO</h2>
                <h2 onClick={() => navigate('/sobre-nos')} className={`cursor-pointer ${verifyRoute('/sobre-nos') ? "font-semibold" : ""}`}>SOBRE NÓS</h2>
                <h2 onClick={() => navigate('/estoque')} className={`cursor-pointer ${verifyRoute('/estoque') ? "font-semibold" : ""}`}>ESTOQUE</h2>
                <h2 onClick={() => navigate('/financiamento')} className={`cursor-pointer ${verifyRoute('/financiamento') ? "font-semibold" : ""}`}>FINANCIAMENTO</h2>
                <h2 onClick={() => navigate('/avaliacao')} className={`cursor-pointer ${verifyRoute('/avaliacao') ? "font-semibold" : ""}`}>VENDA SEU VEÍCULO</h2>
                <h2 onClick={() => navigate('/contato')} className={`cursor-pointer ${verifyRoute('/contato') ? "font-semibold" : ""}`}>CONTATO</h2>
            </div>
            )}
        </div>
    )
}