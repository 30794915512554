import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import { Header } from "./components/header";
import { Footer } from "./components/footer";
import { Sociais } from "./components/sociais";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "./shared/context/auth";
import { getEmails, getEstoque, getInfosLoja, getTelefones, postVisitas } from "./service/api";
import axios from "axios";
import { isMobile } from "./shared/utils/javascript";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Cookies } from "./components/cookies";

function App() {

  const { setTelefone, setIp, setEmail, setEstoque, setInfosLoja } = useContext(AuthContext);
  const [ip, setIP] = useState<string>("")
  
  const handleGetTelefone = async () => {
    try {
        const result = await getTelefones()
        setTelefone(result?.data.retorno ?? []) 
    } catch {
        //
    }
  }
  const handleGetEmail = async () => {
    try {
        const result = await getEmails()
        setEmail(result?.data.retorno[0].email ?? "") 
    } catch {
        //
    }
  }
  const handleGetEstoque = async () => {
    try {
        const result = await getEstoque()
        setEstoque(result?.data.retorno ?? []) 
    } catch {
        //
    }
  }
  const handleGetInfosLoja = async () => {
    try {
        const result = await getInfosLoja()
        setInfosLoja(result?.data ?? {
          loja: {
            nome: "",
            tbl_infosLoja: []
          }
        }) 
    } catch {
        //
    }
  }

  const getData = async () => {
    await axios.get('https://api.ipify.org?format=json')
    .then(response => {
      setIP(response.data.ip);
    })
    .catch(error => {
      console.log(error);
    });
  };

  const salvarAcesso = async () => {
    await getData();
    if(!ip) return

    try {
      await postVisitas({
        ip: ip,
        dispositivo: isMobile() ? 'MOBILE' : 'PC',
        lojaId: Number(process.env.REACT_APP_COD_LOJA)
      });
    } catch (error) {
      //
    }
  }

  useEffect(() => {
    salvarAcesso();
    setIp(ip)
  },[ip])

    useEffect(() => {
      handleGetInfosLoja();
      handleGetTelefone();
      handleGetEmail();
      handleGetEstoque();
    }, []);
  
  return (
      <div className="App">
        <BrowserRouter>
        <ToastContainer />
        <Cookies />
        {!window.location.pathname.includes('admin') ? (<Sociais />): null}
          {!window.location.pathname.includes('admin') ? (<Header />): null}
          <Routes />
          {!window.location.pathname.includes('admin') ? (<Footer />): null}
        </BrowserRouter>
      </div>
  );
}

export default App;
