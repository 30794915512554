import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { isMobile } from '../shared/utils/javascript';
import { AuthContext } from '../shared/context/auth';
import { getEstoque } from '../service/api';

type BuscaQueryType = {
    categoria: string,
    marca: string,
    modelo: string,
    precoDe: string,
    precoAte: string,
    anoDe: string,
    busca: string,
    [key: string]: string;
}

export const FiltroEstoque = () => {

    const location = useLocation()
    const { estoque } = useContext(AuthContext)
    const navigate = useNavigate()
    const [filtroSelect, setFiltroSelect] = useState({
        categoria: "",
        marca: "",
        modelo: "",
        precoDe: 0,
        precoAte: 0,
        anoDe: "",
    });
    const [buscaQuery, setBuscaQuery] = useState<BuscaQueryType>();
    const [optionsModelo, setOptionsModelo] = useState([{value: "Modelo", label: "Modelo" }]);
    const [optionsMarca, setOptionsMarca] = useState([{value: "Marca", label: "Marca" }]);
    const [optionsValorDe, setOptionsValorDe] = useState([0]);
    const [optionsAnoDe, setOptionsAnoDe] = useState([{value: "Ano de", label: "Ano de" }]);
    const [currentValue, setCurrentValue] = useState({from: 0, to: 100000});

    const handleChange = (event: { target: { value: string; }; }, type: string) => {
        const value = parseInt(event.target.value);
        if (type === 'from') {
            if (value < currentValue.to) {
                setCurrentValue({ from: value, to: currentValue.to });
            } else {
                setCurrentValue({ from: currentValue.to - 1, to: currentValue.to });
            }
            handleSelectChangeFiltro('precoDe', value.toString())
        } else {
            if (value > currentValue.from) {
                setCurrentValue({ from: currentValue.from, to: value });
            } else {
                setCurrentValue({ from: currentValue.from, to: currentValue.from + 1 });
            }
            handleSelectChangeFiltro('precoAte', value.toString())
        }
    };

    const handleNavigateFilterSelect = (selectedOption: any) => {
        const paramsObj = { 
            categoria: selectedOption.categoria === "Categoria" ? "" : selectedOption.categoria,
            marca: selectedOption.marca === "Marca" ? "" : selectedOption.marca,
            modelo: selectedOption.modelo === "Modelo" ? "" : selectedOption.modelo,
            precoDe: selectedOption.precoDe === "Valor de" ? "" : selectedOption.precoDe,
            precoAte: selectedOption.precoAte === "Valor até" ? "" : selectedOption.precoAte,
            anoDe: selectedOption.anoDe === "Ano de" ? "" : selectedOption.anoDe,
        };

        const paramsString = encodeURIComponent(JSON.stringify(paramsObj));
      
        const urlComObjeto = `/estoque?params=${paramsString}`;
        navigate(urlComObjeto)
    };

    const handleSelectChangeFiltro = async (tipo: string, value: string) => {
        setFiltroSelect(prevState => ({
            ...prevState,
            [tipo]: value
        }));
        if(tipo === "precoDe" || tipo === "precoAte") {
            setFiltroSelect(prevState => ({
                ...prevState,
                marca: ""
            }))
            setFiltroSelect(prevState => ({
                ...prevState,
                modelo: ""
            }))
            setFiltroSelect(prevState => ({
                ...prevState,
                anoDe: ""
            }))
        }
        if(tipo === "marca") {
            setFiltroSelect(prevState => ({
                ...prevState,
                modelo: ""
            }))
            setFiltroSelect(prevState => ({
                ...prevState,
                anoDe: ""
            }))
        }
        if(tipo === "modelo") {
            setFiltroSelect(prevState => ({
                ...prevState,
                anoDe: ""
            }))
        }
    }

    const handleFilterInput = async () => {
        const filtroMarca = estoque?.filter(item => {
            const categoriaMatch = item.valor >= filtroSelect.precoDe;
            const valorDeMatch = item.valor <= filtroSelect.precoAte;
            
            return categoriaMatch && valorDeMatch;
          });
        const marca = filtroMarca.map((x) => {
            return x.marca
        })
        const listaMarca = marca.filter((element, index) => {
            return marca.indexOf(element) === index;
        }).sort((a, b) => a.localeCompare(b));
        listaMarca.unshift("Marca")
        setOptionsMarca(listaMarca.map((x) => {
            return { value: x === "Marca" ? "" : x, label: x }
        }))
    
        const filtroModelo = estoque?.filter(item => {
            const categoriaMatch = item.valor >= filtroSelect.precoDe;
            const valorDeMatch = item.valor <= filtroSelect.precoAte;
            const marcaMatch = filtroSelect.marca === '' || item.marca === filtroSelect.marca;
            
            return categoriaMatch && marcaMatch && valorDeMatch;
          });
        const modelo = filtroModelo.map((x) => {
            return x.modelo
        })
        const listaModelo = modelo.filter((element, index) => {
            return modelo.indexOf(element) === index;
        }).sort((a, b) => a.localeCompare(b));
        listaModelo.unshift("Modelo")
        setOptionsModelo(listaModelo.map((x) => {
            return { value: x === "Modelo" ? "" : x, label: x }
        }))

        const filtroAnoDe = estoque?.filter(item => {
            const categoriaMatch = item.valor >= filtroSelect.precoDe;
            const valorDeMatch = item.valor <= filtroSelect.precoAte;
            const marcaMatch = filtroSelect.marca === '' || item.marca === filtroSelect.marca;
            const modeloMatch = filtroSelect.modelo === '' || item.modelo === filtroSelect.modelo;
            
            return categoriaMatch && marcaMatch && modeloMatch && valorDeMatch;
          });
        const anoDe = filtroAnoDe.map((x) => {
            return x.ano.toString()
        })
        const listaAnoDe = anoDe.filter((element, index) => {
            return anoDe.indexOf(element) === index;
        });
        
        listaAnoDe.unshift("Ano de")
        setOptionsAnoDe(listaAnoDe.sort((a,b) => Number(b) - Number(a)).map((x) => {
            return { value: x === "Ano de" ? "" : x, label: x }
        }))
    }
    
    useEffect(() => {
        const queryString = location.search;       
        const paramsString = new URLSearchParams(queryString).get('params');
    
        if (paramsString) {
            try {
                const paramsObj = JSON.parse(decodeURIComponent(paramsString));
                if(paramsObj.hasOwnProperty('categoria')) {
                    setBuscaQuery(paramsObj)
                }
            } catch (error) {
                //
            }
        }
    },[])
    
    useEffect(() => {
        handleFilterInput();
        const valorDe = estoque?.map((x) => {
            return x.valor
          })
        const listaValorDe = valorDe.filter((element, index) => {
            return valorDe.indexOf(element) === index;
        });
        setOptionsValorDe(listaValorDe)
    },[estoque])

    useEffect(() => {
        setCurrentValue({from: Math.min(...optionsValorDe), to: Math.max(...optionsValorDe)})
        setFiltroSelect(prevState => ({
            ...prevState,
            precoAte: Math.max(...optionsValorDe)
        }))
    },[optionsValorDe])

    useEffect(() => {
        if(buscaQuery?.precoAte && buscaQuery.precoDe) {
            setCurrentValue({from: Number(buscaQuery?.precoDe), to: Number(buscaQuery?.precoAte)})
        }
    },[buscaQuery])

    useEffect(() => {
        handleFilterInput();
    },[filtroSelect, currentValue])

    useEffect(() => {
        for (const propriedade in buscaQuery) {
            handleSelectChangeFiltro(propriedade, buscaQuery[propriedade])
        }
    },[buscaQuery])
    
    return (
        <div className={`w-full text-black flex flex-col gap-5 ${window.location.pathname === "/" ? "justify-center px-3 max-w-6xl mobile:px-3" : ""}`}>
            <h1 className={`text-white font-semibold text-3xl ${window.location.pathname !== "/" ? "hidden" : ""}`}>Encontre seu carro</h1>
            <div className={`flex flex-col gap-5 w-full mobile:flex-wrap ${window.location.pathname === "/" ? "justify-center" : ""}`}>
                {isMobile() ? (
                    <div className='flex flex-col gap-3'>
                            <div className='flex flex-col gap-3'>
                                <h2 className='text-white text-lg'>Preço</h2>
                                <div className="range_container">
                                    <div className="sliders_control">
                                        <input id="fromSlider" onChange={(event) => handleChange(event, 'from')} type="range" value={currentValue.from} step={1000} min={Math.min(...optionsValorDe)} max={Math.max(...optionsValorDe)}/>
                                        <input id="toSlider" onChange={(event) => handleChange(event, 'to')} type="range" value={currentValue.to} step={1000} min={Math.min(...optionsValorDe)} max={Math.ceil((Math.max(...optionsValorDe) - Math.min(...optionsValorDe)) / 1000) * 1000 + Math.min(...optionsValorDe)}/>
                                    </div>
                                <div className='text-white flex w-full justify-between'>
                                    <h3>{Number(currentValue.from) ? Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(Number(currentValue.from)) : currentValue.from}</h3>
                                    <h3>{Number(currentValue.to) ? Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(Number(currentValue.to)) : currentValue.to}</h3>
                                </div>
                                </div>
                            </div>
                            <select value={filtroSelect.marca} onChange={(event) => handleSelectChangeFiltro('marca', event.target.value)} className="select select-bordered select-radius min-w-[47%] rounded-md">
                                {optionsMarca.map((x) => (
                                    <option key={x.value}>{x.label}</option>
                                ))}
                            </select>
                            <select value={filtroSelect.modelo} onChange={(event) => handleSelectChangeFiltro('modelo', event.target.value)} className="select select-bordered select-radius min-w-min rounded-md">
                                {optionsModelo.map((x) => (
                                    <option key={x.value}>{x.label}</option>
                                ))}
                            </select>
                            <select value={filtroSelect.anoDe} onChange={(event) => handleSelectChangeFiltro('anoDe', event.target.value)} className="select select-bordered select-radius rounded-md">
                                {optionsAnoDe.map((x) => (
                                    <option value={x.value} key={x.value}>{x.label}</option>
                                ))}
                            </select>
                        <div className='flex flex-row gap-5 justify-around'>
                            <button className='btn btn-primary px-8 text-white min-w-[80vw] rounded-md w-full' onClick={() => handleNavigateFilterSelect(filtroSelect)}>Buscar</button>
                        </div>
                    </div>
                ) : (
                    <>
                    <div className='flex flex-col gap-3'>
                        <h2 className='text-white text-lg'>Preço</h2>
                        <div className="range_container">
                            <div className="sliders_control">
                                <input id="fromSlider" onChange={(event) => handleChange(event, 'from')} type="range" value={currentValue.from} step={1000} min={Math.min(...optionsValorDe)} max={Math.max(...optionsValorDe)}/>
                                <input id="toSlider" onChange={(event) => handleChange(event, 'to')} type="range" value={currentValue.to} step={1000} min={Math.min(...optionsValorDe)} max={Math.ceil((Math.max(...optionsValorDe) - Math.min(...optionsValorDe)) / 1000) * 1000 + Math.min(...optionsValorDe)}/>
                            </div>
                        <div className='text-white flex w-full justify-between'>
                            <h3>{Number(currentValue.from) ? Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(Number(currentValue.from)) : currentValue.from}</h3>
                            <h3>{Number(currentValue.to) ? Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(Number(currentValue.to)) : currentValue.to}</h3>
                        </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-4 gap-5'>
                        <select value={filtroSelect.marca} onChange={(event) => handleSelectChangeFiltro('marca', event.target.value)} className="select select-bordered min-w-52 select-radius rounded-md w-full">
                            {optionsMarca.map((x) => (
                                <option key={x.value}>{x.label}</option>
                            ))}
                        </select>
                        <select value={filtroSelect.modelo} onChange={(event) => handleSelectChangeFiltro('modelo', event.target.value)} className="select select-bordered min-w-52 select-radius rounded-md w-full">
                            {optionsModelo.map((x) => (
                                <option key={x.value}>{x.label}</option>
                            ))}
                        </select>
                        <select value={filtroSelect.anoDe} onChange={(event) => handleSelectChangeFiltro('anoDe', event.target.value)} className="select select-bordered min-w-40 select-radius rounded-md w-full">
                            {optionsAnoDe.map((x) => (
                                <option value={x.value} key={x.value}>{x.label}</option>
                            ))}
                        </select>
                        <div className='flex flex-row gap-5'>
                            <button className='btn btn-primary px-8 text-white rounded-md w-full' onClick={() => handleNavigateFilterSelect(filtroSelect)}>Buscar</button>
                        </div>
                    </div>
                    </>
                )}
            </div>
        </div>
    )
}