import { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom';
import { getVisitasAnos, getVisitasDias, getVisitasMeses } from '../../service/api';
import Cookies from 'js-cookie';
import { HeaderAdmin } from './headerAdmin';
import { AsideAdmin } from './asideAdmin';
import { Line } from 'react-chartjs-2';
import { Acessos } from '../../shared/types';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title } from 'chart.js';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title);

export const VisitasAdmin = () => {
    const navigate = useNavigate()
    const [acessosDias, setAcessosDias] = useState<Acessos[]>([])
    const [acessosMeses, setAcessosMeses] = useState<Acessos[]>([])
    const [acessosAnos, setAcessosAnos] = useState<Acessos[]>([])

    const labelsDias = acessosDias?.map((item) => item.dia);
    const dadosDias = acessosDias?.map((item) => item.acessos);
    const labelsMeses = acessosMeses?.map((item) => item.mes);
    const dadosMeses = acessosMeses?.map((item) => item.acessos);
    const labelsAnos = acessosAnos?.map((item) => item.ano);
    const dadosAnos = acessosAnos?.map((item) => item.acessos);

    useEffect(()=> {
        const loginCookies = Cookies.get('token');
        if(loginCookies === undefined) {
            navigate('/admin')
        }
        return
    },[navigate])

    const buscaAcessos = async () => {
        try {
            const acessos = await getVisitasDias()
            setAcessosDias(acessos?.data)
            const acessosMeses = await getVisitasMeses()
            setAcessosMeses(acessosMeses?.data)
            const acessosAnos = await getVisitasAnos()
            setAcessosAnos(acessosAnos?.data)
        } catch (error) {
            //
        }
    }

    useEffect(() => {
        buscaAcessos();
    },[])

    const dataDias = {
        labels: labelsDias,
        datasets: [
          {
            label: 'Acessos por Dia',
            data: dadosDias,
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1
          },
        ],
      };
    const dataMeses = {
        labels: labelsMeses,
        datasets: [
          {
            label: 'Acessos por Dia',
            data: dadosMeses,
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1
          },
        ],
      };
    const dataAnos = {
        labels: labelsAnos,
        datasets: [
          {
            label: 'Acessos por Dia',
            data: dadosAnos,
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1
          },
        ],
      };

    return (
        <div>
            <div className='flex flex-col absolute w-full z-0'>
                <HeaderAdmin />
                <AsideAdmin />
            </div>
            <div className='p-28'>
                <div className='flex items-center gap-3'>
                    <i className="ri-dashboard-3-line ri-3x"></i>
                    <h1 className='font-semibold text-2xl'>Visitas</h1>
                </div>
                <hr className='w-full h-px' />
                <div className='flex flex-col gap-5 z-10 relative'>
                    <div>
                        <div className='flex mt-5 items-center gap-2 ml-5 mb-2'>
                            <i className="ri-line-chart-line ri-2x"></i>
                            <h3 className='text-lg font-semibold'>Visitas dos últimos dias</h3>
                        </div>
                        <div className='rounded-lg p-5 border-slate-400 border-8'>
                            <Line style={{maxHeight: 200}} data={dataDias} />
                        </div>
                    </div>
                    <div>
                        <div className='flex mt-5 items-center gap-2 ml-5 mb-2'>
                            <i className="ri-line-chart-line ri-2x"></i>
                            <h3 className='text-lg font-semibold'>Visitas dos últimos mêses</h3>
                        </div>
                        <div className='rounded-lg p-5 border-slate-400 border-8'>
                            <Line style={{maxHeight: 200}} data={dataMeses}/>
                        </div>
                    </div>
                    <div>
                        <div className='flex mt-5 items-center gap-2 ml-5 mb-2'>
                            <i className="ri-line-chart-line ri-2x"></i>
                            <h3 className='text-lg font-semibold'>Visitas dos últimos anos</h3>
                        </div>
                        <div className='rounded-lg p-5 border-slate-400 border-8'>
                            <Line style={{maxHeight: 200}} data={dataAnos}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}